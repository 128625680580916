import {environmentConfig} from "@/config/enviromentConfig";
import axios from "axios";

const headers = {Authorization: `Basic ${environmentConfig.authHeader}`};

export default class DcbService {
    static async trackLogin(msisdn, service) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/track-login`, {
                    msisdn,
                    service: service,
                    isWeb: 1,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async trackLoginV2(encryptedMsisdn, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/track-login/v2`, {
                    encryptedMsisdn,
                    service: serviceId,
                    isWeb: 1,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async doiOne(msisdn, service) {
        return await axios.post(`${environmentConfig.dcbService}/doi-one`, {
                msisdn,
                serviceId: service,
                origination: "Fanclash - Web"
            }, {headers}
        )
    }

    static async doiTwo(msisdn, service) {
        return await axios.post(`${environmentConfig.dcbService}/doi-two`, {
                msisdn,
                serviceId: service,
                origination: "Fanclash - Web"
            }, {headers}
        )
    }

    static async doiCancel(msisdn, service) {
        return await axios.post(`${environmentConfig.dcbService}/doi-cancel`, {
                msisdn,
                serviceId: service,
                origination: "Fanclash - Web"
            }, {headers}
        )
    }

    static async getMultiTierServiceV2(encryptedMsisdn, productId) {
        return await axios.post(`${environmentConfig.dcbService}/get-multi-tier-service/v2`, {
                encryptedMsisdn: encryptedMsisdn,
                productId,
                acquisitionChannel: `Organic-Web`,
                campaignId: null
            }, {headers}
        )
    }

    static async requestOtp(msisdn, serviceId) {
        return await axios.post(`${environmentConfig.dcbService}/request-otp`, {
                msisdn: msisdn,
                serviceId: serviceId,
            }, {headers}
        )
    }

    static async validateOtp(msisdn, serviceId, otp) {
        return await axios.post(`${environmentConfig.dcbService}/validate-otp`, {
                msisdn: msisdn,
                serviceId: serviceId,
                otp: otp,
            }, {headers}
        )
    }

    static async subscribeUser(msisdn, serviceId, campaignId = 0, clickId) {
        return await axios.post(`${environmentConfig.dcbService}/subscribe`, {
                msisdn,
                serviceId,
                externalRef: 12335642,
                campaignId,
                acquisitionChannel: 'Organic-Web',
                clickId
            }, {headers}
        )
    }

    static async subscribeEncryptedUser(encryptedMsisdn, token, campaignId, evinaToken, contentOverride = undefined, googleClickId, serviceId = 'vc-fanclash-beatthejams-01') {
        try {
            return await axios.post(`${environmentConfig.dcbService}/subscribe/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: serviceId,
                    "externalRef": 12335642,
                    revenuePartnerId: 33,
                    campaignId: campaignId,
                    acquisitionChannel: 'Organic-Web',
                    evinaToken: evinaToken,
                    contentOverride: contentOverride,
                    googleClickId
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async trackUserUpgrade(msisdn, productId, oldService, newService) {
        return await axios.post(`${environmentConfig.dcbService}/track-multi-tier-upgrade`, {
                msisdn,
                productId,
                oldService,
                newService,
            }, {headers}
        )
    }

    static async trackUserUpgradeV2(encryptedMsisdn, productId, oldService, newService) {
        return await axios.post(`${environmentConfig.dcbService}/track-multi-tier-upgrade/v2`, {
                encryptedMsisdn: encryptedMsisdn,
                productId,
                oldService,
                newService,
            }, {headers}
        )
    }

    static async unSubscribeUser(msisdn, serviceId, isDcbOnly, sessionId) {
        return await axios.post(`${environmentConfig.dcbService}/unsubscribe`, {
                msisdn,
                serviceId: serviceId,
                externalRef: 12335642,
                sessionId,
                isDcbOnly,
            }, {
                headers: {
                    Authorization: `Basic ${environmentConfig.authHeaderUnsubscribe}`
                }
            }
        )
    }

    static async unsubscribeEncryptedUser(encryptedMsisdn, serviceId, isDcbOnly) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/unsubscribe/v2`, {
                    encryptedMsisdn,
                    serviceId: serviceId,
                    "externalRef": 12335642,
                    isDcbOnly: isDcbOnly
                },
                {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getUserViaProductId(msisdn, productId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-product-id`, {
                    msisdn,
                    productId: productId,
                    externalRef: 12335642,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getUserViaEncryptedMsisdn(encryptedMsisdn, token, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-encrypted-msisdn/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: serviceId,
                    externalRef: 1233564,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }


    static async createUsername(msisdn, serviceId, username) {
        return await axios.post(`${environmentConfig.dcbService}/update-username-from-msisdn`, {
                msisdn,
                serviceId: serviceId,
                username
            }, {headers}
        )
    }

    static async updateAvatarFromMsisdn(msisdn, serviceId, avatar) {
        return await axios.post(`${environmentConfig.dcbService}/update-avatar-from-msisdn`, {
                msisdn,
                serviceId,
                avatar
            }, {headers}
        )
    }

    static async decryptMsisdn(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.dcbService}/decrypt-msisdn`, {
                encryptedMsisdn,
            }, {headers}
        )
    }

    static async createSessionId(msisdn, serviceId) {
        return await axios.post(`${environmentConfig.dcbService}/create-session-id`, {
                msisdn: msisdn,
                serviceId: serviceId,
            }, {headers}
        )
    }

    static async createUsernameV2(encryptedMsisdn, serviceId, username) {
        return await axios.post(`${environmentConfig.dcbService}/update-username-from-msisdn/v2`, {
                encryptedMsisdn,
                serviceId: serviceId,
                username
            }, {headers}
        )
    }

    static async getEvinaScript(te, msisdn) {
        const response = await axios.post(`${environmentConfig.dcbService}/get-evina-script`, {
                targetElement: te,
                msisdn: msisdn,
                serviceId: "vc-fanclash-beatthejams-01"
            }, {headers}
        )
        return {
            script: Buffer.from(response.data.script.toString(), "base64").toString(),
            evinaToken: response.data.token
        };
    }
}
