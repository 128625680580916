import store from "@/store";
import DcbService from "@/services/dcbService";
import getUserSessionData from "@/functions/getUserSessionData";
import {handleActionClick} from "./handleActionClick";
import {Brands} from "../brand/brandConfig";
import {ServiceIds, setServiceIds} from "../config";
import DepService from "@/services/depService";

export async function userAuthenticate(to, from, next) {
    const {brand} = store.state.brandConfig;
    setServiceIds(brand)
    if (brand === Brands.MtnEnglish) store.commit('setProductId', 35);
    if (brand === Brands.VodacomEvinaSouthAfricaEnglish) await userAuthenticateEncryptedFlow(to, from, next)
    else await userAuthenticateNormalFlow(to, from, next);
}

async function userAuthenticateEncryptedFlow(to, from, next) {
    if (store.state.user || to.name === 'Wifi' || to.name === 'DoiTwo' || to.name === 'TimeoutError') {
        store.commit('setIsLoading', false);
        return next();
    }
    store.commit('setIsLoading', true);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') ?? localStorage.getItem('token');
    const encryptedMsisdn = urlParams.get('encryptedMsisdn') ?? localStorage.getItem('encryptedMsisdn');
    const campaignId = urlParams.get('campaignId') || urlParams.get('cid');
    const googleClickId = urlParams.get('gclid');
    const clickId = urlParams.get('clickId');

    if (campaignId) setCampaignId(campaignId)
    if (googleClickId) setGoogleClickId(googleClickId)
    if (clickId) setClickId(clickId)
    if (encryptedMsisdn && token) {
        return await storeUserDetails(encryptedMsisdn, token, next);
    } else {
        store.commit('setIsLoading', false);
        return next({name: 'Wifi'});
    }
}

async function userAuthenticateNormalFlow(to, from, next) {
    store.commit('setIsLoading', true);
    if (checkUserState(to)) {
        store.commit('setIsLoading', false);
        return next();
    } else {
        const userSessionData = getUserSessionData();
        if (userSessionData.msisdn) await setUserData(userSessionData.msisdn, userSessionData.serviceId, next);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMsisdn = urlParams.get('e-msisdn');
    const campaignId = urlParams.get('campaignId') || urlParams.get('cid');
    const googleClickId = urlParams.get('gclid');
    const clickId = urlParams.get('clickId');
    const msisdn = urlParams.get('msisdn') ?? localStorage.getItem('msisdn');
    const isMiniApp = urlParams.get('miniApp');

    if (msisdn && isMiniApp) return await miniAppLogin(msisdn, next);
    if (campaignId) setCampaignId(campaignId)
    if (googleClickId) setGoogleClickId(googleClickId)
    if (clickId) setClickId(clickId)
    if (encryptedMsisdn) return await encryptedMsisdnAuth(encryptedMsisdn, next);

    store.commit('setIsLoading', false);
    return next();
}

async function miniAppLogin(msisdn, next) {
    localStorage.setItem('isMiniApp', true);
    await getMultiTierService(msisdn);
    return await redirectBasedOnUserStatus(next);
}

function setCampaignId(campaignId) {
    store.commit('setCampaignId', campaignId);
}

function setGoogleClickId(googleClickId) {
    store.commit('setGoogleClickId', googleClickId);
}

function setClickId(clickId) {
    store.commit('setClickId', clickId);
}

async function storeUserDetails(encryptedMsisdn, token, next) {
    const userDataResponse = await getUser(encryptedMsisdn, token);
    await setEncryptedUserData(userDataResponse, token, encryptedMsisdn);
    return await redirectBasedOnUserStatusMarketingFlow(userDataResponse.data, next);
}

async function redirectBasedOnUserStatusMarketingFlow(user, next) {
    //TODO look at this logic !!
    store.commit('setIsLoading', false);
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if (user.status === "Active" && user.serviceId !== ServiceIds.free) return next({name: 'LandingPage'});
    else if (user.status === 'Content Blocked') return next({ path: `/error/:${user.status}` })
    else if (action) return await handleActionClick(user, action, next);
    else return next({name: 'DoiTwo'});
    // Need to implement this page else if(user?.contentBlockedEnabled) return next({name: 'ContentBlockOptIn'});
}

async function getUser(encryptedMsisdn, token) {
    const serviceId = await getMultiTierServiceV2(token);
    return await DcbService.getUserViaEncryptedMsisdn(encryptedMsisdn, token, serviceId);
}

async function setEncryptedUserData(userDataResponse, token = undefined, encryptedMsisdn = undefined) {
    await DcbService.trackLoginV2(token);
    store.commit('setUser', userDataResponse.data);
    store.commit('setToken', token);
    store.commit('setEncryptedMsisdn', encryptedMsisdn);
    localStorage.setItem('token', token);
    localStorage.setItem('encryptedMsisdn', encryptedMsisdn);
}

async function encryptedMsisdnAuth(encryptedMsisdn, next) {
    const decryptMsisdnResponse = await DcbService.decryptMsisdn(encryptedMsisdn);
    await getMultiTierService(decryptMsisdnResponse.data.rawMsisdn)
    return await redirectBasedOnUserStatus(next);
}

async function getMultiTierService(msisdn) {
    let getMultiTierServiceResponse = await DcbService.getUserViaProductId(msisdn, store.state.productId);
    let getServiceIdByDescriptionResponse = getMultiTierServiceResponse.data.serviceId;
    store.commit('setServiceId', getServiceIdByDescriptionResponse);
    store.commit('setMsisdn', msisdn);
}

async function getMultiTierServiceV2(token) {
    const getMultiTierServiceResponse = await DcbService.getMultiTierServiceV2(token, store.state.productId);
    const getServiceIdByDescriptionResponse = getServiceIdByDescription(getMultiTierServiceResponse.data);
    setServiceIdValues(getServiceIdByDescriptionResponse);
    return getServiceIdByDescriptionResponse
}

function setServiceIdValues(serviceId) {
    localStorage.setItem('serviceId', serviceId);
    store.commit('setServiceId', serviceId);
}

async function redirectBasedOnUserStatus(next) {
    const userResponse = store.state.brandConfig.brand === "vc-za-en" ? (await DcbService.getUserViaProductId(store.state.msisdn, store.state.productId)).data : (await DepService.getUserViaProductId(store.state.msisdn, store.state.productId)).data.user;
    store.commit('setUser', userResponse);
    store.commit('setIsLoading', false);
    if (!userResponse) next({name: 'WelcomePage'});
    if (userResponse.username === null) return next({name: 'CreateUsername'});
    else return next({name: 'LandingPage'});
}

function getServiceIdByDescription(array) {
    return array[0].Description;
}

function checkUserState(to) {
    if (store.state.user || to.name === 'TermsConditions') return true
    else return false
}

async function setUserData(msisdn, serviceId, next) {
    store.commit('setMsisdn', msisdn);
    store.commit('setServiceId', serviceId);
    const user = store.state.brandConfig.brand === "vc-za-en" ? (await DcbService.getUserViaProductId(msisdn, store.state.productId)).data : (await DepService.getUserViaProductId(store.state.msisdn, store.state.productId)).data.user;
    store.commit('setUser', user);
    store.commit('setIsLoading', false);
    next();
}
